import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import parse from 'html-react-parser';
import Heading from '../../../components/ui/heading';
import Button from '../../../components/ui/button';
import {Container, Row, Col} from '../../../components/ui/wrapper';
import {HeroWrapper, HeroContent, HeroImagesWrap} from './hero-area.style';

const HeroArea = (props) => {
    const heroData = useStaticQuery(graphql `
        query CybersecurityHeroQuery {
            indexCybersecurityJson(id: {eq: "cybersecurity-hero-content"}) {
                title
                subtitle
                path
            }
        }
    `)

    const {title, subtitle, path} = heroData.indexCybersecurityJson;
    const {subtitleStyle, titleStyle} = props;
    return (
        <HeroWrapper>
            <Container fluid>
                <Row alignitems="center">
                    <Col lg={6} md={7}>
                        <HeroContent>
                            {subtitle && <Heading {...subtitleStyle}>{parse(subtitle)}</Heading>}
                            {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                            {path && <Button hover="2" to={path}>Dig deeper now</Button>}
                        </HeroContent>
                    </Col>
                    <Col lg={6} md={5}>
                        <HeroImagesWrap>
                        </HeroImagesWrap>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

HeroArea.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        texttransform: 'uppercase',
        letterspacing: '3px',
        fontWeight: 700,
        fontSize: '14px',
        mb: '20px'
    },
    titleStyle: {
        as: 'h3',
        mb: '30px',
        child: {
            color: 'primary'
        }
    }
}

export default HeroArea
